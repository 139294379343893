import React, {useEffect, useState} from 'react';
// import loadImg from '../../../Static/Loader/loader.svg'
import AsyncImage from './AsyncImage/AsyncImage';
import s from './question.module.css'
import CountTeam from "../CountTeam/CountTeam";
import ModalResult from "../ModalResult/ModalResult";
import Cookie from 'js-cookie'
import questionState from "../../../State/Questions/QuestionState";
import ModalСonfirmation from "../ModalСonfirmation/ModalСonfirmation";

const QuestionComponent = (props) => {
    const [viewAnswer, setViewAnswer] = useState(false)
    const [viewResultWindow, setViewResultWindow] = useState(false)
    const [countDo, setCountDto] = useState(Cookie.get('count_do') ? Cookie.get('count_do') === 'true' : false)
    const [isNextTask, setIsNextTask] = useState(false)
    const [isViewCountTeam, setIsViewCountTeam] = useState(false)
    const [sizeWindow, setSizeWindow] = useState(window.innerWidth)
    const [isViewModalConfirmation, setIsViewModalConfirmation] = useState(false)

    window.addEventListener(`resize`, event => {
        setSizeWindow(window.innerWidth)

    }, false);

    const resultView = () => {
        setViewResultWindow(true)
    }
    const nextQuestion = ()=>{
        props.nextQuestion()
        setViewAnswer(false)
        setIsViewModalConfirmation(false)
        window.scrollBy(0, -110);

        setIsNextTask(true)
    }
    return (

        <>

            <div className={countDo ? s.wrapper_count : s.wrapper}>
                <div className={s.cardQuestion}>
                    <div className={s.quiz_name}>{props.quizName}</div>
                    <div className={s.formQuestionWrapper}>

                        {/* {props.question.video?
                    <div className={s.imgQuestion}><video type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' autoPlay controls='controls' src={props.question.video} alt=""/></div>:
                    <></>
                } */}
                        <div className={s.question_wrapper}>
                            <div className={s.task_number_wrapper}>

                                {props.categoryImg? <div className={s.wrapper_img_number_task}><img className={s.category_img} src={props.categoryImg} alt=""/> </div>:<></>}

                                <div className={s.wrapper_text_number_task}>
                                    <span className={s.task_number}>Вопрос №{props.taskNumber + 1} </span>
                                    {countDo && !props.isSimple?<span className={s.price_line}>({props.question.price} {props.question.price === 1? 'балл':'балла'})</span>:<></>}
                                </div>
                            </div>
                            <div className={s.textQuestionWrapper}><span>{props.question.text}</span></div>

                            {props.question.img ?
                                <div className={s.img_question_wrapper}>
                                    {/* {loadImg?<Loader/>: */}

                                    <AsyncImage stl={s.img_question} src={props.question.img} loadImg={'/loader.svg'}/>
                                    {/* <img className={s.img_question} onLoad={() => setLoadImg(true)} src={props.question.img} alt=""/> */}
                                </div> :
                                <></>
                            }
                        </div>


                    </div>
                </div>

                {
                    viewAnswer?
                        <div className={s.buttonViewAnswer} onClick={() => {
                            setViewAnswer(!viewAnswer)
                        }}>Ответ: {<span className={s.text_answer}>{props.question.answer}</span>}</div> :
                        <div className={s.buttonViewAnswerView} onClick={() => {
                            setViewAnswer(!viewAnswer)
                        }}>Показать ответ</div>

                }

                {props.isFinishQuestion?
                    <> <div className={s.wrapper_btns_next}>
                        {countDo ?
                        <>
                            {
                                countDo?
                                    <button onClick={()=>{
                                        setIsViewCountTeam(true)
                                    }
                                    } className={s.btn_score_view}>Счет игры</button>:
                                    <></>
                            }
                        <button className={s.btn_exit_quiz} onClick={() => {
                            resultView()
                        }} >Показать результат</button></> :
                        <>
                            {
                                countDo || props.taskNumber===0? <></>:<button className={s.btn_exit_quiz_early} onClick={() => {
                                    props.lastQuestion()
                                }}>Предыдущий вопрос</button>}
                            <button className={s.btn_next_question} onClick={() => {
                            props.closeQuiz()
                        }}>Завершить</button></> }
                    </div>
                    </>
                    :
                        <div className={s.wrapper_btns_next}>
                            {
                                countDo?
                                    <button onClick={()=>{
                                        setIsViewCountTeam(true)
                                    }
                                    } className={s.btn_score_view}>Счет игры</button>:
                                    <></>
                            }
                            {
                                countDo || props.taskNumber===0? <></>:<button className={s.btn_exit_quiz_early} onClick={() => {
                                props.lastQuestion()
                            }}>Предыдущий вопрос</button>
                            }

                            <button className={s.btn_next_question} onClick={() => {
                                if(countDo){
                                    if(!props.isActiveTeams)
                                        props.setIsActiveTeams(true)
                                    else{
                                        setIsViewModalConfirmation(true)
                                        return
                                    }
                                }
                                nextQuestion()

                            }}>Следующий вопрос</button>

                            {
                                countDo?<button onClick={()=>{
                                resultView()
                            }} className={s.btn_exit_quiz_early}>Завершить досрочно</button>:<></>}
                        </div>

                }
            </div>

            {countDo?
                <>
                    {isViewCountTeam || sizeWindow > 771?<> <div className={s.wrapper_score}>
                        {sizeWindow < 770? <div className={s.btn_close_window} onClick={() => {
                            setIsViewCountTeam(false)
                        }}>✕</div>:<></>}
                       <CountTeam
                           idCurrentTeam={props.idCurrentTeam} setIdCurrentTeam={props.setIdCurrentTeam}
                           teams={props.teams} setTeams={props.setTeams}
                           isActiveTeams={props.isActiveTeams} setIsActiveTeams={props.setIsActiveTeams}
                           setIsViewCountTeam={setIsViewCountTeam} price={props.isSimple?3:props.question.price}
                                   setIsNextTask={setIsNextTask} isNextTask={isNextTask} countTeam={props.countTeam}/>

                        {props.isFinishQuestion?
                            <button className={s.btn_next_question_dop} onClick={() => {
                                setIsViewCountTeam(false)
                                resultView()
                            }}>Показать результат</button>:
                            <button className={s.btn_next_question_dop} onClick={() => {
                                setIsViewCountTeam(false)
                                props.setIsActiveTeams(true)
                                nextQuestion()
                                setIsNextTask(true)
                            }}>Следующий вопрос</button>


                        }
                    </div> <div onClick={()=>{setIsViewCountTeam(false)}} className={s.background}></div></>:<></>}

                </>
                : <></>}
            {viewResultWindow ? <ModalResult setViewResultWindow={setViewResultWindow} teams={props.teams} close={props.closeQuiz}/> : <></>}
            {isViewModalConfirmation? <ModalСonfirmation styleBtnNextQuestion={s.btn_next_question}
                                                         styleBtnClose={s.btn_exit_quiz_no_team} nextQuestion={nextQuestion}
                                                         closeWindow={setIsViewModalConfirmation} />:<></>}

        </>
    );
};

export default QuestionComponent;