import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom'
import questionState from "../../State/Questions/QuestionState";
import QuestionComponent from "./QuestionComponent/QuestionComponent";
import {toJS} from "mobx";
import {observer} from "mobx-react-lite";
import NavBarChoiceQuestions from "../Static/NavBarChoiceQuestions";
import Loader from "../../Static/Loader/Loader";
import s from './questionPage.module.css'
import Cookie from 'js-cookie'


const QuestionPage = (observer(() => {


    const [countTeam, setCountTeam] = useState(0)
    const [countDo, setCountDo] = useState(false)
    const[teams, setTeams] = useState([])
    const[isActiveTeams, setIsActiveTeams] = useState(true)
    const [idCurrentTeam, setIdCurrentTeam] = useState(-1)

    const nextQuestion = () => {

        window.scrollBy(0,-110);
        questionState.nextQuestion()
        Cookie.set('task_number', questionState.currentQuestNumber)
        if (teams.length>0)
            Cookie.set('progress',JSON.stringify(teams))
    }

    const lastQuestion = () => {
        Cookie.set('task_number', questionState.currentQuestNumber-1)
        questionState.setCountQuestion(questionState.currentQuestNumber - 1)
    }

    const closeQuiz = (teams=null)=>{
        Cookie.remove('task_number')
        Cookie.remove('progress')
        Cookie.remove('quiz_name')
        window.scrollBy(0,-110);
        questionState.closeQuiz(teams)
    }

    const {id} = useParams();
    const init= async()=>{
        const isCountDo = Cookie.get('count_do')
        setCountDo(isCountDo === 'true')
        if(isCountDo === 'true')
            setCountTeam(Number(Cookie.get('count_team')))

        await questionState.init(id)

        const quizName = Cookie.get('quiz_name')
        const taskNumber = Cookie.get('task_number')
        const progress = Cookie.get('progress')

        if (quizName&&taskNumber){
            if(questionState.quizName === quizName){
                questionState.setCountQuestion(Number(taskNumber))
                if (isCountDo === 'true'){

                    if (progress && JSON.parse(progress).length>0 && Number(Cookie.get('count_team'))===JSON.parse(progress).length)
                        setTeams(JSON.parse(progress))
                    else{
                        questionState.setCountQuestion(0)
                        const teamList = []
                        if (isCountDo === 'true'){
                            for(let i=0;i<Cookie.get('count_team'); i++){
                                teamList.push({id:i,name:"Команда "+(1+i), scores:0})
                            }
                            setTeams(teamList)
                        }

                    }

                }else if(progress){
                    questionState.setCountQuestion(0)
                }

            }else{
                const teamList = []
                if (isCountDo === 'true'){
                    for(let i=0;i<Cookie.get('count_team'); i++){
                        teamList.push({id:i,name:"Команда "+(1+i), scores:0})
                    }
                    setTeams(teamList)
                }

            }
        }else{
            const teamList = []
            if (isCountDo === 'true'){
                for(let i=0;i<Cookie.get('count_team'); i++){
                    teamList.push({id:i,name:"Команда "+(1+i), scores:0})
                }
                setTeams(teamList)
            }

        }
        Cookie.set('quiz_name', questionState.quizName)
    }
    useEffect(()=>{
        window.scrollBy(0,-110);
        init()
    },[])

    return (
            <>
            <NavBarChoiceQuestions/>
            <div className={s.wrapper}>
             
            {questionState.isLoad?
            <div className={s.bodyAsk}>
                  
                {questionState.questions.length>0?

                <QuestionComponent
                                   lastQuestion={lastQuestion}
                                   idCurrentTeam={idCurrentTeam} setIdCurrentTeam={setIdCurrentTeam}
                                   teams={teams} setTeams={setTeams}
                                   isActiveTeams={isActiveTeams} setIsActiveTeams={setIsActiveTeams}
                                   isSimple={questionState.isSimple}
                                   categoryImg={questionState.categoryImg}
                                   countDo={countDo} countTeam={countTeam}
                                   isFinishQuestion={questionState.countQuestions - questionState.currentQuestNumber -1 === 0}
                                   closeQuiz={closeQuiz}
                                   quizName = {questionState.quizName}
                                   nextQuestion={nextQuestion}
                                   taskNumber={questionState.currentQuestNumber}
                                   question={toJS(questionState.currentQuest)}/>
                                   :
                <div className={s.message_not_question}>В данной викторине пока нет вопросов</div>
                }
            </div>:
                <Loader/>
            }


        </div>
        </>)
}))

export default QuestionPage;