import React from 'react';
import s from './navBarChoiceQuestion.module.css'
import logoRGD from "./resource/logo_rgd.svg";

const NavBarChoiceQuestions = () => {
    return (

        <nav className={s.wrapper}>
        {/*<div className="nav-wrapper">*/}
        {/*    <div href="#" className={"brand-logo left " + s.logo}>Викторина</div>*/}
        {/*</div>*/}
            <img className={s.logo_rgd} src={logoRGD} alt=""/>
          <div className={s.logo}>
              <div className={s.logo_up}>интерактивная развивающая игра</div>
              <div  className={s.logo_down}>РОССИЯ В ДЕТАЛЯХ</div>
          </div>
          <div className={s.poin_back} onClick={()=>{
                document.location.replace('/')
          }}>
              {'< Назад'}
          </div>
    </nav>

    );
};
//  <NavItem href="/">
//                     <Icon left>
//                         chevron_left
//                     </Icon>Выход в меню
//                 </NavItem>
export default NavBarChoiceQuestions;