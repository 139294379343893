import React from 'react';
import s from "./count.module.css";
import Cookie from "js-cookie";

const Count = (props) => {

    return (
        <>
            <div className={ s.count_wrapper}>
                <div className={s.wrapper_is_count}>
                <label
                    className={s.task_score}
                    onClick={() => {
                        Cookie.set('count_do', !props.countDo)
                        if (!props.countDo) {
                            Cookie.set('count_team', props.countTeam)
                        } else {
                            Cookie.remove('count_team')
                        }
                        props.setCountDo(!props.countDo)
                    }}>Вести счет игры </label>

                <input className={s.task_score_checkbox}
                       onChange={(e) => {
                           Cookie.set('count_do', !props.countDo)
                           if (!props.countDo) {
                               Cookie.set('count_team', props.countTeam)
                           } else {
                               Cookie.remove('count_team')
                           }
                           props.setCountDo(!props.countDo)
                       }} checked={props.countDo} type='checkbox'/>
                </div>
                {props.countDo ?
                <div className={s.wrapper_count_team}>

                    <span className={s.count_team_label}>Введите количество команд</span>

                    <div className={s.wrapper_count_team_add}>
                        <div className={s.count_team_label_add} onClick={() => {
                            if (props.countTeam > 1 && props.countDo) {
                                props.setCountTeam(props.countTeam - 1)
                                Cookie.set('count_team', props.countTeam - 1)
                            }

                        }}>-
                        </div>
                        <span className={s.count_team}>{props.countTeam}</span>
                        <div className={s.count_team_label_add} onClick={() => {
                            if (props.countTeam < 10 && props.countDo) {
                                props.setCountTeam(1 + props.countTeam)
                                Cookie.set('count_team', 1 + props.countTeam)
                            }

                        }}>+
                        </div>
                    </div>

                </div>:<></>}
            </div>

            {/*<div className={s.wrapper_quiz_blocks}>*/}
            {/*<div*/}
            {/*    className={props.countDo ? s.count_wrapper_active : s.count_wrapper}>*/}
            {/*    <label*/}
            {/*        className={s.task_score}*/}
            {/*        onClick={() => {*/}
            {/*            Cookie.set('count_do', !props.countDo)*/}
            {/*            if (!props.countDo) {*/}
            {/*                Cookie.set('count_team', props.countTeam)*/}
            {/*            } else {*/}
            {/*                Cookie.remove('count_team')*/}
            {/*            }*/}
            {/*            props.setCountDo(!props.countDo)*/}
            {/*        }}>Вести счет игры </label>*/}
            {/*    <input className={s.task_score_checkbox}*/}
            {/*           onChange={(e) => {*/}
            {/*               Cookie.set('count_do', !props.countDo)*/}
            {/*               if (!props.countDo) {*/}
            {/*                   Cookie.set('count_team', props.countTeam)*/}
            {/*               } else {*/}
            {/*                   Cookie.remove('count_team')*/}
            {/*               }*/}
            {/*               props.setCountDo(!props.countDo)*/}
            {/*           }} checked={props.countDo} type='checkbox'/>*/}


            {/*    /!*<div className={props.countDo ? s.wrapper_count_team : s.wrapper_count_team_no_active}>*!/*/}
            {/*    /!*    <span className={s.count_team_label}>Введите количество команд</span>*!/*/}
            {/*    /!*    <div className={s.wrapper_count_team_add}>*!/*/}
            {/*    /!*        <button className={s.count_team_label_add} onClick={() => {*!/*/}
            {/*    /!*            if (props.countTeam > 1 && props.countDo) {*!/*/}
            {/*    /!*                props.setCountTeam(props.countTeam - 1)*!/*/}
            {/*    /!*                Cookie.set('count_team', props.countTeam - 1)*!/*/}
            {/*    /!*            }*!/*/}

            {/*    /!*        }}>-*!/*/}
            {/*    /!*        </button>*!/*/}
            {/*    /!*        <span className={s.count_team_label_data}>{props.countTeam}</span>*!/*/}
            {/*    /!*        <button className={s.count_team_label_add} onClick={() => {*!/*/}
            {/*    /!*            if (props.countTeam < 10 && props.countDo) {*!/*/}
            {/*    /!*                props.setCountTeam(1 + props.countTeam)*!/*/}
            {/*    /!*                Cookie.set('count_team', 1 + props.countTeam)*!/*/}
            {/*    /!*            }*!/*/}

            {/*    /!*        }}>+*!/*/}
            {/*    /!*        </button>*!/*/}
            {/*    /!*    </div>*!/*/}

            {/*    /!*</div>*!/*/}
            {/*</div>*/}
        {/*</div>*/}
            </>

    );
};

export default Count;