import React, {useEffect, useState} from 'react';
import QuizComponent from "./QuizComponent/QuizComponent";
import NavBarChoiceQuiz from "../Static/NavBarChoiceQuiz";
import Loader from "../../Static/Loader/Loader";
import {observer} from "mobx-react-lite";
import quizState from "../../State/Quiz/QuizState";
import {toJS} from "mobx";
import s from './choicehQuizPage.module.css'
import config from '../../config';
import Description from "../Description/Description";
import categoryState from '../../State/Category/CategoryState'
import Cookie from 'js-cookie'
import Count from "./Count/Count";

const ChoiceQuizPage = (observer((props) => {
    const [categoryList, setCategoryList] = useState([])
    const localInit = async () => {
        Cookie.set('count_do', false)
        const res = await categoryState.getCategory()
        if (!res.warning) {
            setCategoryList(res.category)

        }
        await quizState.init()
    }
    useEffect(() => {
        localInit()


    }, [])
    const [isSearchEmpty, setIsSearchEmpty] = useState(false)
    const [isSearch, setIsSearch] = useState(false)
    const [quizzes, setQuizzes] = useState([])
    const [categorys, setCategorys] = useState([])
    const [viewCategorys, setViewCategorys] = useState(false)
    const [currentCategory, setCurrentCategory] = useState('')
    const [countDo, setCountDo] = useState(false)
    const [countTeam, setCountTeam] = useState(2)

    if (!isSearch && quizzes.length === 0 && quizState.isLoad) {

        const quizList = toJS(quizState.quizzes)
        setQuizzes(quizList)
        const categoryList = [config.GENERAL_CATEGORY]
        quizList.forEach(quiz => {

                if (categoryList.indexOf(quiz.category) < 0) {
                    categoryList.push(quiz.category)
                }


            }
        )

        categoryList.splice(0, 1)
        categoryList.sort()
        setCurrentCategory(config.GENERAL_CATEGORY)
        setCategorys([config.GENERAL_CATEGORY, ...categoryList])
        if (categoryList.length >= 1) {
            setViewCategorys(true);
        } else
            setViewCategorys(false)
    }
    const countComponent = <Count countDo={countDo} countTeam={countTeam}
                                  setCountDo={setCountDo} setCountTeam={setCountTeam} />
    return (
        <>
            {quizState.marker === 'not_description' ?
                <>
                    <Description/>
                </>
                : <></>
            }
            <div className={s.wrapper}>

                <NavBarChoiceQuiz/>
                {quizState.isLoad ?

                       <>

                           <div className={s.wrapper_quiz_blocks}>

                            {quizzes.map(quiz => {

                                    return (
                                        <div key={'quiz_row' + quiz.id} className={s.row_quiz}>
                                            <QuizComponent
                                                countComponent={countComponent}
                                                category={categoryList.filter(item => item.name === quiz.description)}
                                                key={'quiz' + quiz.id} quiz={quiz}/>
                                        </div>
                                    )
                                }
                            )


                            }
                           </div>
                       </>
                     :
                    <Loader/>}

            </div>
        </>
    )
}))

export default ChoiceQuizPage;