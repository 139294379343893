import {flow, makeAutoObservable, makeObservable, runInAction} from "mobx"
import responseService from "../../Services/responseService";
import config from "../../config";
import QuizClass from "./QuizClass";

class QuizState {

    quizzes = []
    isLoad = false
    // currentQuizId
    message
    warning = false
    marker = ''

    constructor() {
        // this.currentQuizId = 0
        // this.quizzes = quizDefault()
        //console.log(quizDefault())
        // this.currentQuizId = this.quizzes[0].id
        makeAutoObservable(this)
    }

    async init(isAdmin=false){
        this.isLoad = false
        const to = config.URL_GET_QUIZ + (isAdmin?'_admin':'')
        const response = await responseService.responseApiServer(to)

        this.quizzes = []
            runInAction(()=>{

                if (!response.warning) {
                    this.quizzes = response.data
                    if(response.marker  && response.marker === 'not_description'){
                       this.marker = 'not_description'
                    }
                } else {
                    this.warning = true
                    this.message = response.message
                    setTimeout(()=>{
                        this.init()
                        return
                    },500)
                }
                this.isLoad = true
            })
    }

    getEmptyGame(){
        return {...new QuizClass()}
    }

    async saveQuiz(quiz){
        let to
        if(quiz.id)
             to = config.URL_UPDATE_QUIZ
        else
            to = config.URL_SAVE_QUIZ

        await responseService.responseApiServer(to,{quiz:{...quiz}})
        await this.init(true)
    }

    async removeQuiz(id){
        const to = config.URL_REMOVE_QUIZ
        await responseService.responseApiServer(to,{id})
        await this.init(true)
    }
}

const quizState = new QuizState();

export default quizState;

