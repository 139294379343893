import React from 'react';
import s from './FilesModal.module.css'
import blank from '../Static/resource/game_form.pdf'
import cert from '../Static/resource/cert.jpg'

const FilesModal = (props) => {
    return (
        <>
            <div onClick={()=>{   props.closeWindow(false)}} className={'modal_background'}></div>


            <div className={s.wrapper}>
                <div className={s.close_window} onClick={()=>{
                    props.closeWindow(false)
                }}>✕</div>

                <div className={s.content}>
                    <a className={s.href} href={blank} download={'Игровой бланк'} >Скачать игровой бланк</a>
                    <a className={s.href} href={cert} download={'Сертификат соответствия'} >Скачать сертификат соответствия</a>
                </div>
            </div>
        </>
    );
};

export default FilesModal;