import React from 'react';
import s from './navBarChoiceQuiz.module.css'
import logoRGD from './resource/logo_rgd.svg'

const NavBarChoiceQuiz = () => {
    return (


        <nav className={s.wrapper}>
            {/*<div className="nav-wrapper">*/}
            {/*    <div href="#" className={"brand-logo left " + s.logo}>Викторина</div>*/}
            {/*</div>*/}
              <div className={s.logo_rgd}><img src={logoRGD} alt=""/></div>
              <div className={s.logo}>
                  <div className={s.logo_up}>интерактивная развивающая игра</div>
                  <div  className={s.logo_down}>РОССИЯ В ДЕТАЛЯХ</div>

              </div>
        </nav>

    );
};

export default NavBarChoiceQuiz;